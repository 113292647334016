

const Title = ({title}) => {


    return (

        <div class="lg:text-base xl:text-lg">    
        <h2>{title}</h2>
        </div>

    );
  }
  
export default Title;

